import React from 'react'

const KYCPending = () => (
	<div className="section-heading text-center kyc-status">
		<i className="fad fa-users-cog fa-2x text-primary" />
		<h3>ข้อมูลของคุณอยู่ระหว่างตรวจสอบ</h3>
		<h6>
			รอการดำเนินการตรวจสอบข้อมูล <b>24 - 72</b> ชั่วโมง
		</h6>
	</div>
)

export default KYCPending
