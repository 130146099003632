/* eslint-disable jsx-a11y/anchor-is-valid */
import { useFormik } from 'formik'
import { Payment } from 'omise-js-typed/src/lib/utils'
import React, { useState } from 'react'
import { chargeService } from '../../services/charge'
import BaseAlert from '../Base/BaseAlert'
import BaseButton from '../Base/BaseButton'
import BaseAmountChoose from '../Base/BaseAmountChoose'
import BaseSelect from '../Base/BaseSelect'
import clsx from 'clsx'
import BaseInput from '../Base/BaseInput'

const InternetBank = () => {
    const [topTab, setTopTab] = useState<string>('ordinary')

    const [agree, setAgree] = useState(false)
    const [error, setError] = useState<string | null>(null)
    const { values, isSubmitting, handleChange, handleSubmit } = useFormik({
        initialValues: {
            bank: 'internet_banking_scb',
            amount: 20,
        },
        onSubmit: (values, { setSubmitting }) => {
            if (values.amount < 20 || values.amount > 100000) {
                setError('จำนวนเงินต้องอยู่ระหว่าง 20 - 100,000 บาท')
                setSubmitting(false)
                return
            }

            window.Omise.createSource(
                values.bank as Payment,
                {
                    amount: values.amount * 100,
                    currency: 'THB',
                },
                async (statusCode, response) => {
                    if (statusCode === 200) {
                        try {
                            const charge = await chargeService.createCharge({
                                amount: parseInt(values.amount.toString()),
                                currency: 'THB',
                                redirectURL: window.location.origin,

                                sourceID: response.id,
                            })

                            window.location.href = charge.redirectURL
                        } catch ({ message }: any) {
                            setError(message as string)
                            setSubmitting(false)
                        }
                    } else {
                        setError('ไม่สามารถทำรายการได้ในขณะนี้')
                        setSubmitting(false)
                    }
                }
            )
        },
    })
    return (
        <div className="xver-box payment-block">
            <ul className="tab tab-block">
                <li
                    className={clsx('tab-item', {
                        active: topTab === 'ordinary',
                    })}
                >
                    <a
                        onClick={() => setTopTab('ordinary')}
                        style={{ cursor: 'pointer' }}
                    >
                        เลือกจำนวน
                    </a>
                </li>
                <li
                    className={clsx('tab-item', {
                        active: topTab === 'amount',
                    })}
                >
                    <a
                        onClick={() => setTopTab('amount')}
                        style={{ cursor: 'pointer' }}
                    >
                        กำหนดเอง
                    </a>
                </li>
            </ul>

            <div className="box-content">
                {error && <BaseAlert error={error} />}
                <form autoComplete="off" onSubmit={handleSubmit}>
                    <BaseSelect
                        name="bank"
                        label="ธนาคาร"
                        defaultValue={values.bank}
                        required
                        onChange={handleChange}
                    >
                        {/* <option value='internet_banking_scb'>
                            ธนาคารไทยพาณิชย์ (SCB)
                        </option>
                        <option value='internet_banking_ktb'>
                            ธนาคารกรุงไทย (KTB)
                        </option> */}
                        <option value="internet_banking_bbl">
                            ธนาคารกรุงเทพ (BBL)
                        </option>
                        <option value="internet_banking_bay">
                            ธนาคารกรุงศรีอยุธยา (BAY)
                        </option>
                    </BaseSelect>
                    {topTab === 'ordinary' && (
                        <BaseAmountChoose
                            name="amount"
                            label="จำนวนเงิน"
                            defaultValue={values.amount}
                            required
                            onChange={handleChange}
                        />
                    )}
                    {topTab === 'amount' && (
                        <BaseInput
                            name="amount"
                            label="จำนวนเงิน"
                            defaultValue={values.amount}
                            required
                            onChange={handleChange}
                        />
                    )}
                    <div className="form-group form-topup-terms">
                        <label className="form-checkbox">
                            <input
                                type="checkbox"
                                onChange={() => setAgree(!agree)}
                            />
                            <i className="form-icon" />{' '}
                            ฉันยอมรับว่าการเติมเครดิตไม่สามารถขอคืนเงินได้ทุกกรณี{' '}
                            <a href="/refund-policy">อ่านเพิ่มเติม</a>
                        </label>
                    </div>

                    <div className="form-group mt-1">
                        <BaseButton
                            type="submit"
                            color="primary"
                            classes="btn-lg btn-block"
                            disabled={!agree}
                            loading={isSubmitting}
                        >
                            เติมเงิน
                        </BaseButton>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default InternetBank
