import classNames from 'classnames'
import { ButtonHTMLAttributes, FC } from 'react'

interface BaseButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	color?:
		| 'primary'
		| 'light-primary'
		| 'secondary'
		| 'error'
		| 'success'
		| 'warning'
	classes?: string
	loading?: boolean
	disabled?: boolean
}
const BaseButton: FC<BaseButtonProps> = ({
	color,
	classes,
	loading,
	disabled,
	children,
	...rest
}) => (
	<button
		{...rest}
		className={classNames(classes, `btn btn-${color}`, {
			loading: loading,
		})}
		disabled={disabled}>
		{children}
	</button>
)

export default BaseButton
