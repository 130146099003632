export enum KYCLevel {
    L0 = "l0",
    L1 = "l1",
    L2 = "l2",
}
export enum KYCStatus {
    Pending = "pending",
    Confirmed = "confirmed",
}

export enum EmailLevel {
    E0 = "e0",
    E1 = "e1",
    E2 = "e2",
}

export interface UserInformation {
    firstName: string;
    lastName: string;
    address: string;
    province: string;
    zipCode: string;
}
export interface User {
    id: number;
    email: string;
    phoneNumber: string;
    credit: number;
    status: "active" | "suspended" | "insufficient_funds";

    information: UserInformation;
    kyc: {
        level: KYCLevel;
        status: KYCStatus;
    };

    createdAt: string;
    updatedAt: string;

    verifyEmailAddress: string;

    identifier: string;
}
