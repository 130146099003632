import { useFormik } from "formik";
import React, { Fragment } from "react";
import { toast } from "react-toastify";
import { authService } from "../../../services/auth";
import PinInput from "react-pin-input";
import { Spacer, Text, Button, ButtonGroup } from "@geist-ui/core";
import BaseInput from "src/components/Base/BaseInput";
import BaseButton from "src/components/Base/BaseButton";
import { Inbox, MessageCircle } from "@geist-ui/icons";
import { useHistory } from "react-router";

type VerifyType = "email" | "phone";

const ChangePasswordSection = () => {
  const router = useHistory();

  const [verifyState, setVerifyState] = React.useState<Boolean>(false);
  const [verifyLoading, setVerifyLoading] = React.useState<boolean | undefined>(
    false
  );
  const [otp, setOtp] = React.useState<string>("");

  const { isSubmitting, handleChange, handleSubmit } = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
      confirm_password: "",
    },
    onSubmit: async (values) => {
      try {
        const res = await authService.changePassword(otp, values);

        toast.success(res.message);

        router.push('/sign-in');
      } catch ({ message }: any) {
        toast.error(message as string);
      }
    },
  });

  const VerifyActioner = async (verifyTypePayload: VerifyType) => {
    try {
      setVerifyLoading(true);

      const response = await authService.verifyChangePassowrd(
        localStorage.getItem("x-auth") as string,
        { change_type: verifyTypePayload }
      );

      setVerifyLoading(false);
      setVerifyState(true);

      return toast.success(response.message);
    } catch ({ message }: any) {
      setVerifyLoading(false);

      return toast.error(message as string);
    }
  };

  return (
    <Fragment>
      {verifyState ? (
        <Fragment>
          <div
            style={{
              width: "100%",
              height: "auto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text type={"secondary"} margin={0}>
              กรุณาใส่รหัสยืนยันที่ระบบได้ส่งให้คุณ
            </Text>
            <PinInput
              length={6}
              initialValue={""}
              type="numeric"
              inputMode="number"
              style={{
                padding: "10px",
              }}
              inputStyle={{
                borderColor: "#d5d5d5",
                borderRadius: "0.2rem",
              }}
              inputFocusStyle={{
                borderColor: "#3b82f6",
              }}
              onComplete={(value, _) => setOtp(value)}
              autoSelect={true}
              regexCriteria={/^[0-9]*$/}
            />
          </div>
          <form autoComplete="off" onSubmit={handleSubmit}>
            <BaseInput
              type="password"
              name="old_password"
              label="รหัสผ่านเก่า"
              required
              onChange={handleChange}
            />
            <BaseInput
              type="password"
              name="new_password"
              label="รหัสผ่านใหม่"
              required
              onChange={handleChange}
            />
            <BaseInput
              type="password"
              name="confirm_password"
              label="ยืนยันรหัสผ่านใหม่"
              required
              onChange={handleChange}
            />
            <div className="form-group">
              <BaseButton
                type="submit"
                color="light-primary"
                classes="btn-lg btn-block"
                loading={isSubmitting}
              >
                เปลี่ยนรหัสผ่าน
              </BaseButton>
            </div>
          </form>
        </Fragment>
      ) : (
        <Fragment>
          <div className="section-heading">
            <h3>เปลี่ยนรหัสผ่านของคุณ</h3>
            <Text font={"13px"} type={"secondary"}>
              เลือกวิธีที่คุณต้องการยืนยันตัวตนสำหรับการเปลี่ยนรหัสผ่าน
            </Text>
          </div>
          <ButtonGroup vertical width={"100%"}>
            <Button
              auto
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              padding={"6px"}
              onClick={() => VerifyActioner("email")}
              loading={verifyLoading}
            >
              <Inbox size={"20px"} />
              <Spacer w={"12px"} />
              <Text>ยืนยันตัวตนผ่านอีเมลล์</Text>
            </Button>
            <Button
              auto
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              padding={"6px"}
              onClick={() => VerifyActioner("phone")}
              loading={verifyLoading}
            >
              <MessageCircle size={"20px"} />
              <Spacer w={"12px"} />
              <Text>ยืนยันตัวตนผ่านเบอร์โทรศัพท์</Text>
            </Button>
          </ButtonGroup>
        </Fragment>
      )}
    </Fragment>
  );
};

export default ChangePasswordSection;
