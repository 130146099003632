/* eslint-disable array-callback-return */

import { useFormik } from "formik"
import React, { FC, Fragment, useState } from "react"
import addressJson from './../../../assets/json/address.json';
import { useDispatch } from "react-redux"
import { useHistory } from "react-router"
import { toast } from "react-toastify"
import { User } from "../../../@types/user"
import { kycService } from "../../../services/kyc"
import { setAuthUser } from "../../../store/auth/auth.actions"
import BaseButton from "../../Base/BaseButton"
import BaseInput from "../../Base/BaseInput"
import BaseSelect from "src/components/Base/BaseSelect";
import { Address } from "src/@types/address";

interface KYCLevel1Props {
    user: User
}
const KYCLevel1: FC<KYCLevel1Props> = ({ user }) => {
    const [addresses] = useState<Address>(addressJson);
    const [agree, setAgree] = useState(false)

    const [province, setProvince] = useState<string>('');
    const [district, setDistrict] = useState<string>('');
    const [subDistrict, setSubDistrict] = useState<string>('');

    const [districtState, setDistrictState] = useState<boolean>(true);
    const [subDistrictState, setSubDistrictState] = useState<boolean>(true);

    const [postalCode, setPostalCode] = useState<string>('');

    const DistrictOptions = addresses.map((provinceItem) => {
        if (provinceItem.name_th === province) {
            return provinceItem.amphure.map((district, index) => (
                <option key={index} value={district.name_th}>
                    {district.name_th}
                </option>
            ));
        }
    });

    const SubDistrictOptions = addresses.map((provinceItem) => {
        if (provinceItem.name_th === province) {
            return provinceItem.amphure.map((districtItem) => {
                if (districtItem.name_th === district) {
                    return districtItem.tambon.map((subDistrictItem, index) => (
                        <option key={index} value={subDistrictItem.name_th}>
                            {subDistrictItem.name_th}
                        </option>
                    ));
                }
            });
        }
    });

    function ProvinceOnChangeEventHandler(value: string) {
        if (value.length < 1) {
            setProvince('');
            setDistrict('');
            setSubDistrict('');

            setDistrictState(true);
            setSubDistrictState(true);

        } else {
            setProvince(value);
            setDistrict('');
            setSubDistrict('');

            setDistrictState(false);
            setSubDistrictState(true);
        }
    }

    function DistrictOnChangeEventHandler(value: string) {
        if (value.length < 1) {
            setDistrict('');
            setSubDistrictState(true);

        } else {
            setDistrict(value);
            setSubDistrictState(false);
        }
    }

    function SubDistrictOnChangeEventHandler(value: string) {
        if (value.length < 1) {
            setSubDistrict('');

        } else {
            setSubDistrict(value);
        }
    }

    function AddressConcatenator(value: string): string | undefined {
        if (value === 'กรุงเทพมหานคร') {
            return `, แขวง ${district},  ${subDistrict}, ${province} (ประเทศไทย) ${postalCode}`;
        } else {
            return `, อำเภอ ${district}, ตำบล ${subDistrict}, จังหวัด ${province} (ประเทศไทย) ${postalCode}`;
        }
    }

    const dispatch = useDispatch()
    const history = useHistory()
    const { isSubmitting, handleChange, handleSubmit } = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            address: "",
            province: "",
            zipCode: "",
        },
        onSubmit: async (values) => {
            if (province.length === 0) {
                toast.error("กรุณาใส่ชื่อจังหวัดหรือปกครองให้ถูกต้อง")
                return
            }

            if (district.length === 0) {
                toast.error("กรุณาใส่ชื่ออำเภอหรือแขวงให้ถูกต้อง")
                return
            }

            if (subDistrict.length === 0) {
                toast.error("กรุณาใส่ชื่อตำบลหรือให้ถูกต้อง")
                return
            }

            if ((postalCode.length === 0) || (postalCode.length < 5)) {
                toast.error("กรุณาใส่เลขรหัสไปรษณีย์ให้ถูกต้อง")
                return
            }

            try {
                const res = await kycService.confirmKYCLevel1({
                    firstName: values.firstName,
                    lastName: values.lastName,
                    address: values.address + AddressConcatenator(province),
                    province: province,
                    zipCode: postalCode
                })

                dispatch(setAuthUser(res.data))
                toast.success(res.message)

                history.push("/cloud-servers")
            } catch ({ message }: any) {
                toast.error(message as string)
            }
        },
    })

    return (
        <Fragment>
            <div className="section-heading text-center">
                <i className="fad fa-user-edit fa-2x" />
                <h3>
                    ยืนยันตัวตนขั้นที่ <b>1</b>
                </h3>
            </div>

            <form autoComplete="off" onSubmit={handleSubmit}>
                <div className="columns">
                    <div className="column col-6">
                        <BaseInput
                            type="text"
                            label="อีเมล"
                            defaultValue={user?.email}
                            disabled
                        />
                    </div>
                    <div className="column col-6">
                        <BaseInput
                            type="text"
                            label="เบอร์โทรศัพท์"
                            defaultValue={user?.phoneNumber}
                            disabled
                        />
                    </div>
                </div>

                {/* Name */}
                <div className="columns">
                    <div className="column col-6">
                        <BaseInput
                            type="text"
                            name="firstName"
                            label="ชื่อ"
                            maxLength={50}
                            required
                            onChange={handleChange}
                        />
                    </div>
                    <div className="column col-6">
                        <BaseInput
                            type="text"
                            name="lastName"
                            label="นามสกุล"
                            maxLength={50}
                            required
                            onChange={handleChange}
                        />
                    </div>
                </div>

                {/* Address */}
                <BaseInput
                    type="text"
                    name="address"
                    label="ที่อยู่ตามบัตรประชาชน (บ้านเลขที่, หมู่, หมู่บ้าน)"
                    required
                    onChange={handleChange}
                />
                <div className="columns">
                    <div className="column col-6">
                        <BaseSelect
                            name="province"
                            label="จังหวัดหรือการปกครองพิเศษ (คลิกเพื่อเลือก)"
                            value={province}
                            onChange={(event) => ProvinceOnChangeEventHandler(event.target.value)}
                        >
                            <option value={''}>กรุณาเลือกพื้นที่จังหวัด</option>
                            {addresses.map((province, index) => {
                                return (
                                    <option key={index}>{province.name_th}</option>
                                )
                            })}
                        </BaseSelect>
                    </div>
                    <div className="column col-6">
                        <BaseSelect
                            name="district"
                            label="อำเภอหรือแขวง (คลิกเพื่อเลือก)"
                            value={district}
                            disabled={districtState}
                            onChange={(event) => DistrictOnChangeEventHandler(event.target.value)}
                        >
                            <option value={''}>
                                {province ? 'กรุณาเลือกพื้นที่อำเภอ' : 'กำลังรอการเลือกพื้นที่จังหวัด'}
                            </option>
                            {DistrictOptions}
                        </BaseSelect>
                    </div>
                </div>
                <div className="columns">
                    <div className="column col-6">
                        <BaseSelect
                            name="sub-district"
                            label="ตำบลหรือ (คลิกเพื่อเลือก)"
                            value={subDistrict}
                            disabled={subDistrictState}
                            onChange={(event) => SubDistrictOnChangeEventHandler(event.target.value)}
                        >
                            <option value={''}>
                                {district ? 'กรุณาเลือกพื้นที่ตำบล' : 'กำลังรอการเลือกพื้นที่อำเภอ'}
                            </option>
                            {SubDistrictOptions}
                        </BaseSelect>
                    </div>
                    <div className="column col-6">
                        <BaseInput
                            type="text"
                            name="postal-code"
                            label="รหัสไปรษณีย์"
                            maxLength={5}
                            onChange={(event) => setPostalCode(event.target.value)}
                            required
                        />
                    </div>
                </div>

                <div className="form-group form-kyc-info">
                    * เมื่อยืนยันตัวตนสำเร็จ คุณจะสามารถสร้างได้สูงสุด <b>3</b>{" "}
                    เซิร์ฟเวอร์ *
                </div>
                <div className="form-group form-kyc-terms">
                    <label className="form-checkbox">
                        <input
                            type="checkbox"
                            onChange={() => setAgree(!agree)}
                        />
                        <i className="form-icon" />{" "}
                        ฉันยอมรับว่าข้อมูลทั้งหมดเป็นความจริง
                        และตกลงให้ความยินยอมรวมถึงอนุญาตตามเงื่อนไขที่กำหนดทั้งหมด
                    </label>
                </div>
                <div className="form-group mt-1">
                    <BaseButton
                        type="submit"
                        color="primary"
                        classes="btn-lg btn-block"
                        loading={isSubmitting}
                        disabled={!agree}
                    >
                        ยืนยัน
                    </BaseButton>
                </div>
            </form>
        </Fragment>
    )
}

export default KYCLevel1
