const DebugBar = () => {
    return (
        <>
            {process.env.CF_PAGES_BRANCH !== 'master' && (
                <div className="topright">
                    {`${process.env.CF_PAGES_BRANCH} (${process.env.CF_PAGES_COMMIT_SHA})`}
                </div>
            )}
        </>
    )
}

export default DebugBar
