import clsx from 'clsx'
import React, { ChangeEvent, FC, InputHTMLAttributes, useState } from 'react'

interface BaseInputProps {
	label: string
}
const BaseInput: FC<BaseInputProps & InputHTMLAttributes<HTMLInputElement>> = ({
	label,
	name,
	className,
	onChange,
	...rest
}) => {
	const [value, setValue] = useState(rest.value || rest.defaultValue || '')
	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		setValue(e.currentTarget.value)
		onChange && onChange(e)
	}

	return (
		<label className="form-floating-label" htmlFor={name}>
			<span className={clsx({ active: value !== '' })}>{label}</span>
			<input
				name={name}
				className={clsx(className, { active: value !== '' })}
				placeholder={label}
				onChange={handleChange}
				{...rest}
			/>
		</label>
	)
}

export default BaseInput
