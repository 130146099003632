import {
	Charge,
	CreateChargeData,
	CreateChargeResponse,
} from '../@types/charge'
import api from '../utils/api'

export const chargeService = {
	createCharge(data: CreateChargeData): Promise<CreateChargeResponse> {
		return api.post('/v1/charges', data)
	},
	getChargeInfo(sourceID: string): Promise<Charge> {
		return api.get(`/v1/charges/${sourceID}`)
	},
}
