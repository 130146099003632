import React from 'react'
import useSWR from 'swr'
import { sshKeyService } from '../../../services/sshKey'
import AddSSHKeySection from './AddSSHKeySection'
import SSHKeyList from './SSHKeyList'

const SSHKeyTab = () => {
	const { data } = useSWR('/v1/ssh-keys', sshKeyService.getSSHKeys)

	if (!data) {
		return <div className="loading xver-loading" />
	}
	return (
		<div className="columns">
			<div className="column col-10 col-sm-12 col-mx-auto">
				<SSHKeyList data={data} />

				<AddSSHKeySection />
			</div>
		</div>
	)
}

export default SSHKeyTab
