import clsx from 'clsx'
import qs from 'querystring'
import React, { FC, Fragment, useState } from 'react'
import { useLocation } from 'react-router'
import AccountVerficationTab from '../components/Account/AccountVerificationTab/AccountVerificationTab'
import SecurityTab from '../components/Account/SecurityTab'
import SSHKeyTab from '../components/Account/SSHKeyTab'
import AccountInformation from 'src/components/Account/AccountInformation/AccountInformation'

interface AccountTabMenu {
	tab: string
	icon: JSX.Element
	title: string
	description: string
}

interface AccountTabsProps {
	items: AccountTabMenu[]

	activeTab: string
	changeTab: (tab: string) => void
}
const AccountTabs: FC<AccountTabsProps> = ({ items, activeTab, changeTab }) => (
	<Fragment>
		{items.map((item) => (
			<div
				className={clsx('xver-tab-menu', { active: item.tab === activeTab })}
				key={item.tab}
				onClick={() => changeTab(item.tab)}
			>
				{item.icon}
				<span>
					<h1>{item.title}</h1>
					<h6>{item.description}</h6>
				</span>
			</div>
		))}
	</Fragment>
)

const Account = () => {
	const params = qs.parse(useLocation().search.substring(1))

	const [tab, setTab] = useState(params.kyc ? 'account-verification' : 'account-information')
	const tabs: AccountTabMenu[] = [
        {
            tab: 'account-information',
            icon: <i className="fas fa-user-circle" />,
            title: 'Account Information',
            description: 'ข้อมูลบัญชีผู้ใช้งาน'
        },
		{
			tab: 'security',
			icon: <i className="fad fa-user-shield" />,
			title: 'Security',
			description: 'ความปลอดภัยของบัญชี',
		},
		{
			tab: 'account-verification',
			icon: <i className="fad fa-badge-check" />,
			title: 'Account Verification',
			description: 'การยืนยันตัวตน',
		},
		{
			tab: 'ssh-keys',
			icon: <i className="fad fa-key" />,
			title: 'SSH Keys',
			description: 'Key-based authentication',
		},
	]

	return (
		<div className="block">
			<div className="header-with-element">
				<h2 className="mb-0">
                    <i className="fad fa-user" />
                    &nbsp;บัญชีผู้ใช้งาน
                </h2>
			</div>
			<div className="xver-content">
				<div className="columns">
					<div className="column col-4 col-sm-12">
						<AccountTabs items={tabs} activeTab={tab} changeTab={setTab} />
					</div>
					<div className="column col-8 col-sm-12">
                        {tab === 'account-information' && <AccountInformation />}
						{tab === 'security' && <SecurityTab />}
						{tab === 'account-verification' && <AccountVerficationTab />}
						{tab === 'ssh-keys' && <SSHKeyTab />}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Account
