import React, { Dispatch, FC, LabelHTMLAttributes, SetStateAction } from 'react'
import { Link } from 'react-router-dom'

interface BaseTermOfServiceProps
    extends LabelHTMLAttributes<HTMLButtonElement> {
    state: [boolean, Dispatch<SetStateAction<boolean>>]
    custom_text1?: string
    custom_text2?: string
    to: string
}

const BaseTermOfService: FC<BaseTermOfServiceProps> = ({
    state,
    custom_text1,
    custom_text2,
    to,
    children,
}) => {
    const [agree, setAgree] = state

    const handleAgreesChange = () => {
        setAgree(!agree)
    }
    return (
        <label className='form-checkbox'>
            <input
                type='checkbox'
                defaultChecked={agree}
                onChange={handleAgreesChange}
            />
            <i className='form-icon' />
            {custom_text1 ? custom_text1 : 'ฉันได้อ่านและยอมรับ'}
            <Link to={to} target='_blank'>
                {custom_text2 ? custom_text2 : 'เงื่อนไขและข้อตกลงการให้บริการ'}
            </Link>
        </label>
    )
}

export default BaseTermOfService
