import React from 'react'

import useCopy from './../../../hooks/useCopy'

interface CopyButtonProps {
    textToCopy: string

    label?: string
    fontColor?: string
    fontBold?: boolean
}

const CopyButton: React.FC<CopyButtonProps> = ({
    textToCopy,
    label = 'คัดลอก',
    fontColor = '#000000',
    fontBold = false,
}: CopyButtonProps) => {
    const [, COPY] = useCopy(textToCopy)
    const CopyHandler = () => COPY()

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                flexWrap: 'nowrap',
            }}
            onClick={CopyHandler}
            className="copy-button"
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexWrap: 'nowrap',
                }}
            >
                <span
                    className="copy-button-text"
                    style={{
                        fontWeight: fontBold ? 600 : 400,
                    }}
                >
                    {label}
                </span>
                &nbsp;
                <span className="copy-button-icon">
                    <i className="fas fa-copy" />
                </span>
            </div>
        </div>
    )
}

export default CopyButton
