import { BillingResponse, HistoryBillResponse, UnsetResponse, UpdateBillingRequest, UpdateBillingResponse } from "../@types/bill";
import api from "../utils/api";

export const billService = {
    unsetBilling(): Promise<UnsetResponse> {
        return api.get("/v1/billing/check-unset-billing");
    },
    historyBill(page?: number, pageSize?: number): Promise<HistoryBillResponse> {
        return api.get(`/v1/billing/history?page=${page}&page_size=${pageSize}`);
    },
    updateBilling(billingData: UpdateBillingRequest): Promise<UpdateBillingResponse> {
        return api.post("/v1/billing/update-billing", billingData);
    },
    billing(): Promise<BillingResponse> {
        return api.get("/v1/billing/billing");
    }
};