import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { couponService } from "../../services/coupon";
import { requestAuthUser } from "../../store/auth/auth.actions";
import BaseAlert from "../Base/BaseAlert";
import BaseButton from "../Base/BaseButton";
import BaseInput from "../Base/BaseInput";
import { toast } from "react-toastify";

const Coupon = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const couponCode = localStorage.getItem("coupon") ?? "";

    const [error, setError] = useState<string | null>(null);
    const formik = useFormik({
        initialValues: {
            coupon: couponCode,
        },
        onSubmit: async (values, { setSubmitting }) => {
            try {
                const res = await couponService.Redeem(values.coupon);
                dispatch(requestAuthUser());

                localStorage.removeItem("coupon");

                history.push("/billing/status/complete", res);
            } catch ({ message }: any) {
                localStorage.removeItem("coupon");
                setError(message as string);
                setSubmitting(false);
            }
        },
    });

    useEffect(() => {
        if (couponCode) {
            toast.success("คุณสามารถกดปุ่ม 'ยืนยัน' เพื่อใช้คูปอง");
        }
    }, [couponCode]);

    return (
        <div className="xver-box payment-block">
            <div className="box-content">
                {error && <BaseAlert error={error} />}
                <form autoComplete="off" onSubmit={formik.handleSubmit}>
                    <BaseInput
                        type="text"
                        name="coupon"
                        label="รหัสคูปองโปรโมชั่น"
                        required
                        value={formik.values.coupon}
                        onChange={formik.handleChange}
                    />
                    <div className="form-group">
                        <BaseButton
                            type="submit"
                            color="primary"
                            classes="btn-lg btn-block"
                            loading={formik.isSubmitting}
                        >
                            ยืนยัน
                        </BaseButton>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Coupon;
