import actionCreatorFactory from 'typescript-fsa'
import { ThunkDispatch } from '../../@types/store'
import { User } from '../../@types/user'
import { authService } from '../../services/auth'

const actionCreator = actionCreatorFactory('@@xver/auth')

export const setAuthUser = actionCreator<User | undefined>('setAuthUser')

export const requestAuthUser = () => async (dispatch: ThunkDispatch) => {
	try {
		const user = await authService.getUserInfo()

		dispatch(setAuthUser(user))
	} catch (e) {
		console.error(e)
	}
}
