import React from 'react'
import classNames from 'classnames'
import { FC, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Link, NavLink } from 'react-router-dom'
import { EmailLevel, KYCLevel, User } from '../../@types/user'
import { format } from '../../utils/number'
import { authService } from 'src/services/auth'
import { toast } from 'react-toastify'
import CopyButton from '../ControlInstance/OverviewTab/CopyButton'
import DebugBar from '../Debug/DebugBar'

interface AppLayoutProps {
    user: User
}

const AppLayout: FC<AppLayoutProps> = ({ user, children }) => {
    const history = useHistory()

    const [sidebar, setSidebar] = useState(false)

    const [sending, setSending] = React.useState(false)

    const sendVerificationCode = async () => {
        setSending(true)

        try {
            const res = await authService.sendEmailAddressVerificationCode(
                localStorage.getItem('x-auth') ?? ''
            )

            toast.success(res.message)
            setSending(false)
        } catch ({ message }: any) {
            toast.error(message as string)
        } finally {
            setSending(false)
        }
    }

    useEffect(() => {
        const unsubscribe = history.listen(() => {
            setSidebar(false)
        })
        return () => {
            unsubscribe()
        }
    }, [history])

    return (
        <React.Fragment>
            <div className="modal" id="logout">
                {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                <a
                    className="modal-overlay"
                    href="#logout-closed"
                    aria-label="Close"
                />
                <div
                    className="modal-container"
                    style={{
                        width: '420px',
                        borderRadius: '6px',
                    }}
                    role="document"
                >
                    <div className="modal-header">
                        <div className="modal-title h5">
                            <i className="fad fa-engine-warning text-warning" />
                            &nbsp; คุณต้องการที่จะล็อกเอ้าท์?
                        </div>
                    </div>
                    <div className="xver-balance">
                        <CopyButton
                            textToCopy={user.email}
                            label={user.email}
                            fontColor="#ffffff"
                        />
                        <h2 className="mb-0">
                            {format(user.credit)}
                            <span className="balance-currency">฿</span>
                        </h2>
                        <h6>ยอดเงินคงเหลือ</h6>
                    </div>
                    <div className="modal-footer">
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexWrap: 'nowrap',
                            }}
                        >
                            <a
                                href="#logout-closed"
                                style={{
                                    padding: '10px 0',
                                    width: '100%',
                                    border: 'none',
                                    textAlign: 'center',
                                    borderRadius: '6px',
                                    backgroundColor: '#7c7c7c',
                                    textDecoration: 'none',
                                    color: '#ffffff',
                                    cursor: 'pointer',
                                }}
                            >
                                กลับสู่หน้าเมนู
                            </a>
                            <button
                                onClick={() => void history.push('/sign-out')}
                                style={{
                                    marginTop: '8px',
                                    padding: '10px 0',
                                    width: '100%',
                                    border: 'none',
                                    borderRadius: '6px',
                                    backgroundColor: '#ef4444',
                                    color: '#ffffff',
                                    cursor: 'pointer',
                                }}
                            >
                                ล็อกเอ้าท์
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="xver-container off-canvas off-canvas-sidebar-show">
                <DebugBar />
                <div
                    className={classNames('xver-overlay', { active: sidebar })}
                    onClick={() => setSidebar(false)}
                />
                <div
                    className={classNames('xver-sidebar off-canvas-sidebar', {
                        active: sidebar,
                    })}
                >
                    <div className="xver-brand text-center">
                        <div className="xver-logo">
                            <h2 className="d-inline">XVer</h2>
                            <small className="label label-primary text-bold">
                                Cloud
                            </small>
                        </div>
                        <div className="xver-balance">
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                &nbsp;&nbsp;
                                <div style={{ paddingBottom: 2 }}>
                                    <CopyButton
                                        textToCopy={user.email}
                                        label={user.email}
                                        fontColor="#ffffff"
                                    />
                                </div>
                            </div>
                            <h2 className="mb-0">
                                {format(user.credit)}
                                <span className="balance-currency">฿</span>
                            </h2>
                            <h6>ยอดเงินคงเหลือ</h6>
                        </div>
                    </div>
                    <div className="xver-nav">
                        <ul>
                            <li>
                                <NavLink to="/cloud-servers">
                                    <div className="d-flex">
                                        <i className="fas fa-cloud" />
                                        <h6>
                                            Cloud Servers
                                            <br />
                                            <small>คลาวด์เซิร์ฟเวอร์</small>
                                        </h6>
                                    </div>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/dedicated-servers">
                                    <div className="d-flex">
                                        <i className="fad fa-server" />
                                        <h6>
                                            Dedicated Servers
                                            <br />
                                            <small>เซิร์ฟเวอร์ส่วนตัว</small>
                                        </h6>
                                    </div>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/billing">
                                    <div className="d-flex">
                                        <i className="fad fa-wallet" />
                                        <h6>
                                            Billing
                                            <br />
                                            <small>การเรียกเก็บเงิน</small>
                                        </h6>
                                    </div>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/account">
                                    <div className="d-flex">
                                        <i className="fad fa-user" />
                                        <h6>
                                            Account
                                            <br />
                                            <small>บัญชีผู้ใช้งาน</small>
                                        </h6>
                                    </div>
                                </NavLink>
                            </li>
                            <li>
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                <a
                                    href="#"
                                    onClick={() => {
                                        // @ts-ignore
                                        window.$chatwoot.toggle()
                                    }}
                                >
                                    <div className="d-flex">
                                        <i className="fad fa-ticket" />
                                        <h6>
                                            Support
                                            <br />
                                            <small>การช่วยเหลือ</small>
                                        </h6>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="#logout">
                                    <div className="d-flex">
                                        <i className="fad fa-sign-out" />
                                        <h6>
                                            Log Out
                                            <br />
                                            <small>ออกจากระบบ</small>
                                        </h6>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="off-canvas-content">
                    <div className="xver-navbar-burger">
                        <div>
                            <button
                                className="off-canvas-toggle btn btn-link btn-action"
                                onClick={() => setSidebar(true)}
                                style={{
                                    fontSize: '32px',
                                }}
                            >
                                <i className="fad fa-bars" />
                            </button>
                        </div>
                    </div>
                    <div className="xver-navbar">
                        {user.verifyEmailAddress !== EmailLevel.E2 && (
                            <div
                                style={{
                                    width: '100%',
                                    height: 'auto',
                                    padding: '10px 16px',
                                }}
                            >
                                <div className="xver-verification-box">
                                    <div
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                        }}
                                    >
                                        <span className="xver-verification-header">
                                            <i className="fad fa-badge-check" />
                                            &nbsp;
                                            ยืนยันตัวตนเพื่อเพิ่มสิทธิ์การใช้งาน
                                        </span>
                                        <span style={{ color: '#7c7c7c' }}>
                                            การยืนยันอีเมลของคุณได้ส่งไปยัง{' '}
                                            <b>{user.email}</b> แล้ว
                                            ถ้าคุณไม่เจอให้เช็คที่{' '}
                                            <u>จดหมายขยะ</u>{' '}
                                            หรือกดส่งคำขอการยืนยันใหม่
                                            (หมดอายุภายใน 10 นาที)
                                        </span>
                                    </div>
                                    <div className="xver-btn-wrap">
                                        <button
                                            className="xver-btn"
                                            disabled={sending}
                                            onClick={() =>
                                                void sendVerificationCode()
                                            }
                                        >
                                            <i className="fas fa-paper-plane"></i>
                                            <span>
                                                &nbsp;&nbsp;ส่งคำขอยืนยัน
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                        {user.kyc.level === KYCLevel.L0 && (
                            <div
                                style={{
                                    width: '100%',
                                    height: 'auto',
                                    padding: '10px 16px',
                                }}
                            >
                                <div className="xver-verification-box">
                                    <div
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                        }}
                                    >
                                        <span className="xver-verification-header">
                                            <i className="fad fa-badge-check" />
                                            &nbsp;
                                            ยืนยันตัวตนเพื่อเพิ่มโควต้าทรัพยากร
                                        </span>
                                        <span style={{ color: '#7c7c7c' }}>
                                            คุณจำเป็นต้องยืนยันตัวตนด้วยบัตรประชาชนหรืออื่นๆ
                                            เพื่อแสดงหลักฐานการมีตัวตนของยูสเซอร์{' '}
                                            <b>{user.email}</b>
                                        </span>
                                    </div>
                                    <div className="xver-btn-wrap">
                                        <Link
                                            to="/account?kyc=1"
                                            className="xver-btn"
                                            style={{
                                                color: '#ffffff',
                                                textDecoration: 'none',
                                            }}
                                        >
                                            <i className="fas fa-user-circle"></i>
                                            <span>&nbsp;&nbsp;ยืนยันตัวตน</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="xver-main-content">{children}</div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AppLayout
