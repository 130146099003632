import { CommonResponse } from '../@types/api'
import { User } from '../@types/user'
import api from '../utils/api'

interface SignInData {
	email: string
	password: string
}
interface SignInResponse {
	message: string
	data?: {
		token?: string

		phoneNumber: string
		emailAddress: string
		verificationLevel: string
		verificationToken: string
	}
}

interface SignUpData {
	email: string
	password: string
	confirmPassword: string
	phoneNumber: string
}
interface SignUpResponse {
	message: string
	data?: {
		phoneNumber: string
		verificationToken: string
	}
}

interface SendVerificationCodeResponse {
	message: string
	data: {
		otpRef: string
	}
}

interface VerifyPhoneData {
	otpRef: string
	otpCode: string
}
interface VerifyPhoneResponse {
	token: string
}

interface ChangePasswordData {
	old_password: string
	new_password: string
	confirm_password: string
}

interface RequestResetPasswordPayload {
	email: string
	phoneNumber: string
}
interface RequestResetPasswordResponse {
	message: string
	data: {
		userID: number
		otpRef: string
	}
}

interface RequestVerifyChangePasswordResponse {
	message: string;
	data: {
		otpRef: string
	}
}

interface ConfirmResetPasswordPayload {
	otpCode: string
	password: string
}

interface SendEmailAddressVerificationCodeResponse {
	message: string;
}

interface VerifyEmailAddressByRefResponse {
	token: string;
}

interface ResetEmailAddressPayload {
	newEmailAddress: string;
    currentlyPassword: string
}

interface ResetEmailAddressResponse {
	message: string;
}

interface VerifyChangePasswordPayload {
	change_type: string
}

export const authService = {
	signIn(data: SignInData): Promise<SignInResponse> {
		return api.post('/v1/auth/sign-in', data)
	},
	signUp(data: SignUpData): Promise<SignUpResponse> {
		return api.post('/v1/auth/sign-up', data)
	},
	sendVerificationCode(token: string): Promise<SendVerificationCodeResponse> {
		return api.post(`/v1/auth/send-verify-phone?token=${token}`)
	},
	verifyPhone(token: string, data: VerifyPhoneData): Promise<VerifyPhoneResponse> {
		return api.post(`/v1/auth/verify-phone?token=${token}`, data)
	},
	getUserInfo(): Promise<User> {
		return api.get('/v1/auth')
	},
	verifyChangePassowrd(token: string, data: VerifyChangePasswordPayload): Promise<RequestVerifyChangePasswordResponse> {
		return api.post(`/v1/auth/request-change-password?token=${token}`, data);
	},
	changePassword(otp: string, data: ChangePasswordData): Promise<CommonResponse> {
		return api.post(`/v1/auth/change-password?otp=${otp}`, data)
	},

	requestResetPassword(payload: RequestResetPasswordPayload): Promise<RequestResetPasswordResponse> {
		return api.post(`/v1/auth/reset-password`, payload)
	},
	confirmResetPassword(userID: number, payload: ConfirmResetPasswordPayload): Promise<CommonResponse> {
		return api.post(`/v1/auth/reset-password/${userID}`, payload)
	},
	sendEmailAddressVerificationCode(token: string): Promise<SendEmailAddressVerificationCodeResponse> {
		return api.post(`/v1/auth/send-verify-email?token=${token}`)
	},
	verifyEmailAddressByRef(ref: string, token: string): Promise<VerifyEmailAddressByRefResponse> {
		return api.post(`/v1/auth/verify-email?ref=${ref}&token=${token}`);
	},
	requestResetEmailAddress(token: string, payload: ResetEmailAddressPayload): Promise<SendVerificationCodeResponse> {
		return api.post(`/v1/auth/request-reset-email-address?token=${token}`, payload)
	},
    handleResetEmailAddress(otp: string, token: string): Promise<ResetEmailAddressResponse> {
		return api.post(`/v1/auth/reset-email-address?otp=${otp}&token=${token}`)
	}
}
