import React from 'react';

import copyToClipboard from 'copy-to-clipboard';

import { toast } from 'react-toastify';

// @ts-ignore
export default function useClipboard(initial: string): [boolean, () => void] {
    const COPYABLE = React.useRef<string>(initial);

    const [COPIED, SET_COPIED] = React.useState<boolean>(false);
    const COPY_ACTION = React.useCallback(() => {
        const COPIED_INITIAL =  copyToClipboard(COPYABLE.current);
        SET_COPIED(COPIED_INITIAL);

        toast.success(`คุณได้ทำการคัดลอก ${initial} แล้ว!`);
    }, [COPYABLE, initial]);

    React.useEffect(() => {
        COPYABLE.current = initial;
    }, [initial])

    return [COPIED, COPY_ACTION];
}