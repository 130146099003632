import clsx from 'clsx'
import { ChangeEvent, FC, SelectHTMLAttributes, useState } from 'react'

interface BaseAmountChooseProps {
	label: string
}
const BaseAmountChoose: FC<BaseAmountChooseProps & SelectHTMLAttributes<HTMLSelectElement>> = ({
	label,
	name,
	className,
	onChange,
	...rest
}) => {
	const [value, setValue] = useState(rest.value || rest.defaultValue || '')
	const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
		setValue(e.currentTarget.value)
		onChange && onChange(e)
	}

	return (
		<label className="form-floating-label mb-0" htmlFor={name}>
			<span className={clsx({ active: value !== '' })}>{label}</span>

			<div className="form-group">
				<select
					className={clsx(className, { active: value !== '' }, 'form-select')}
					name={name}
					onChange={handleChange}
				>
					<option value={'20'}>20 บาท</option>
					{/* <option value={'50'}>50 บาท</option> */}
					<option value={'100'}>100 บาท</option>
					<option value={'150'}>150 บาท</option>
					<option value={'200'}>200 บาท</option>
					<option value={'250'}>250 บาท</option>
					<option value={'300'}>300 บาท</option>
					<option value={'500'}>500 บาท</option>
					<option value={'1000'}>1,000 บาท</option>
					<option value={'3000'}>3,000 บาท</option>
					<option value={'5000'}>5,000 บาท</option>
					<option value={'10000'}>10,000 บาท</option>
					{/* <option value={'15000'}>15,000 บาท</option> */}
					<option value={'20000'}>20,000 บาท</option>
					<option value={'30000'}>30,000 บาท</option>
					{/* <option value={'50000'}>50,000 บาท</option>
                    <option value={'100000'}>100,000 บาท</option>
                    <option value={'500000'}>500,000 บาท</option>
                    <option value={'1000000'}>1,000,000 บาท</option> */}
				</select>
			</div>
		</label>
	)
}

export default BaseAmountChoose
