import axios, { AxiosError } from 'axios'

const baseRequest = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT || 'http://localhost:5001',
    withCredentials: true,
})

baseRequest.interceptors.request.use((config) => {
    const token = localStorage.getItem('x-auth')

    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
})

baseRequest.interceptors.response.use(
    (res) => res.data,
    (err: AxiosError) => {
        if (err.response?.data.message) {
            return Promise.reject(err.response.data)
        }
        if (err.message) {
            return Promise.reject({
                message: err.message,
            })
        }
        return Promise.reject({
            message: 'ไม่สามารถเชื่อมต่อเซิร์ฟเวอร์ได้ในขณะนี้',
        })
    }
)

export default baseRequest
