/* eslint-disable jsx-a11y/anchor-is-valid */
import { useFormik } from 'formik'
import { useState } from 'react'
import { chargeService } from '../../services/charge'
import BaseAlert from '../Base/BaseAlert'
import BaseAmountChoose from '../Base/BaseAmountChoose'
import BaseButton from '../Base/BaseButton'
import clsx from 'clsx'
import BaseInput from '../Base/BaseInput'

const Card = () => {
    const [topTab, setTopTab] = useState<string>('ordinary')

    const [agree, setAgree] = useState(false)
    const [error, setError] = useState<string | null>(null)
    const { values, isSubmitting, handleChange, handleSubmit } = useFormik({
        initialValues: {
            name: '',
            cardNumber: '',
            securityCode: '',
            expiration: '',
            amount: 20,
        },
        onSubmit: (values, { setSubmitting }) => {
            if (values.amount < 20 || values.amount > 100000) {
                setError('จำนวนเงินต้องอยู่ระหว่าง 20 - 100,000 บาท')
                setSubmitting(false)
                return
            }

            // const expirationPars = values.expiration.split("/")
            // if (expirationPars.length !== 2) {
            //     setError("วันหมดอายุบัตรไม่ถูกต้อง")
            //     setSubmitting(false)
            //     return
            // }
            window.OmiseCard.open({
                amount: parseInt(values.amount.toString()) * 100,
                currency: 'THB',
                defaultPaymentMethod: 'credit_card',
                async onCreateTokenSuccess(nonce) {
                    try {
                        const charge = await chargeService.createCharge({
                            amount: parseInt(values.amount.toString()),
                            currency: 'THB',
                            redirectURL: window.location.origin,

                            token: nonce,
                        })

                        window.location.href = charge.redirectURL
                    } catch ({ message }: any) {
                        setError(message as string)
                        setSubmitting(false)
                    }
                },
                onFormClosed() {
                    setError('ยกเลิกการชำระเงิน')
                    setSubmitting(false)
                },
            })
            // window.Omise.createToken(
            //     "card",
            //     {
            //         name: values.name,
            //         number: values.cardNumber.replaceAll(" ", ""),
            //         expiration_month: parseInt(expirationPars[0]),
            //         expiration_year: parseInt(expirationPars[1]),
            //         security_code: parseInt(values.securityCode),
            //     },
            //     async (statusCode, response) => {
            //         if (statusCode === 200) {
            //             try {
            //                 const charge = await chargeService.createCharge({
            //                     amount: parseInt(values.amount.toString()),
            //                     currency: "THB",
            //                     redirectURL: window.location.origin,

            //                     token: response.id,
            //                 })

            //                 window.location.href = charge.redirectURL
            //             } catch ({ message }) {
            //                 setError(message as string)
            //                 setSubmitting(false)
            //             }
            //         } else {
            //             setError(response.message)
            //             setSubmitting(false)
            //         }
            //     }
            // )
        },
    })
    return (
        <div className="xver-box payment-block">
            <ul className="tab tab-block">
                <li
                    className={clsx('tab-item', {
                        active: topTab === 'ordinary',
                    })}
                >
                    <a
                        onClick={() => setTopTab('ordinary')}
                        style={{ cursor: 'pointer' }}
                    >
                        เลือกจำนวน
                    </a>
                </li>
                <li
                    className={clsx('tab-item', {
                        active: topTab === 'amount',
                    })}
                >
                    <a
                        onClick={() => setTopTab('amount')}
                        style={{ cursor: 'pointer' }}
                    >
                        กำหนดเอง
                    </a>
                </li>
            </ul>

            <div className="box-content">
                {error && <BaseAlert error={error} />}
                <form autoComplete="off" onSubmit={handleSubmit}>
                    {/* <BaseInput
                        type="text"
                        name="name"
                        label="ชื่อบนบัตร"
                        required
                        onChange={handleChange}
                    />
                    <BaseInput
                        type="text"
                        name="cardNumber"
                        label="หมายเลขบัตร"
                        required
                        onChange={handleChange}
                    />

                    <div className="columns mb-05">
                        <div className="column col-6">
                            <BaseInput
                                type="text"
                                name="expiration"
                                label="วันหมดอายุบัตร"
                                required
                                onChange={handleChange}
                            />
                        </div>
                        <div className="column col-6">
                            <BaseInput
                                type="text"
                                name="securityCode"
                                label="Security Code"
                                required
                                onChange={handleChange}
                            />
                        </div>
                    </div> */}

                    {topTab === 'ordinary' && (
                        <BaseAmountChoose
                            name="amount"
                            label="จำนวนเงิน"
                            defaultValue={values.amount}
                            required
                            onChange={handleChange}
                        />
                    )}
                    {topTab === 'amount' && (
                        <BaseInput
                            name="amount"
                            label="จำนวนเงิน"
                            defaultValue={values.amount}
                            required
                            onChange={handleChange}
                        />
                    )}
                    <div className="form-group form-topup-terms">
                        <label className="form-checkbox">
                            <input
                                type="checkbox"
                                onChange={() => setAgree(!agree)}
                            />
                            <i className="form-icon" />{' '}
                            ฉันยอมรับว่าการเติมเครดิตไม่สามารถขอคืนเงินได้ทุกกรณี{' '}
                            <a href="/refund-policy">อ่านเพิ่มเติม</a>
                        </label>
                    </div>
                    <div className="form-group mt-1">
                        <BaseButton
                            type="submit"
                            color="primary"
                            classes="btn-lg btn-block"
                            disabled={!agree}
                            loading={isSubmitting}
                        >
                            เติมเงิน
                        </BaseButton>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Card
