import { CommonResponse } from '../@types/api'
import { AddSSHKeyData, SSHKey } from '../@types/sshKey'
import api from '../utils/api'

export const sshKeyService = {
	getSSHKeys(): Promise<SSHKey[]> {
		return api.get('/v1/ssh-keys')
	},
	addSSHKey(data: AddSSHKeyData): Promise<CommonResponse> {
		return api.post('/v1/ssh-keys', data)
	},
	deleteSSHKey(id: number): Promise<CommonResponse> {
		return api.delete(`/v1/ssh-keys/${id}`)
	},
}
