import useSWR from "swr";
import { billService } from "../../services/bill";
import { BillingResponse } from "src/@types/bill";

interface BillingInfoProps {
    info: BillingResponse | undefined
}

const BillingInfo = ({ info }: BillingInfoProps) => {
    const { data: checkUnset } = useSWR("/v1/billing/check-unset-billing", billService.unsetBilling);

    const date = new Date(info?.createdAt as string);
    const result = date.toLocaleDateString("th-TH", {
        hour: "numeric",
        minute: "numeric",
        year: "numeric",
        month: "long",
        day: "numeric",
        weekday: "long",
      })

    return (
        <div className="card-body">
            <div className="h6">Company (Optional)</div>
            <p className="text-gray">
                ชื่อบริษัทหรือธุรกิจ (ถ้ามี)
            </p>
            <p className="text-primary">
                {checkUnset?.status === false ? (
                    info?.company
                ) : "คุณไม่ได้ตั้งค่าไว้"}
            </p>

            <div className="h6">Address</div>
            <p className="text-gray">
                ที่อยู่นี้ปรากฏใน
                ใบแจ้งหนี้/ใบเสร็จรับเงิน
                ควรเป็นที่อยู่ตามกฎหมายของบ้านหรือธุรกิจของคุณ
            </p>
            <p className="text-primary">
                {checkUnset?.status === false ? (
                    info?.address
                ) : "คุณไม่ได้ตั้งค่าไว้"}
            </p>

            <div className="h6">Tax ID</div>
            <p className="text-gray">
                เลขประจำตัวผู้เสียภาษีอากร
            </p>
            <div className="text-primary">
                {checkUnset?.status === false ? (
                    info?.taxid
                ) : "คุณไม่ได้ตั้งค่าไว้"}
            </div>
            <div className="text-dark latest-billing-update">
                {checkUnset?.status === false ? (
                    "อัปเดตล่าสุด: " + result
                ) : "อัปเดตล่าสุด: ไม่ได้บันทึกไว้"}
            </div>
        </div>
    );
}

export default BillingInfo;
