import classNames from 'classnames'
import React, { FC } from 'react'

interface BaseAlertProps {
	success?: string
	warning?: string
	error?: string
	info?: string
}
const BaseAlert: FC<BaseAlertProps> = ({ success, warning, error, info }) => {
	let color = 'primary',
		message = 'unknow'
	if (success) {
		color = 'success'
		message = success
	} else if (warning) {
		color = 'warning'
		message = warning
	} else if (error) {
		color = 'error'
		message = error
	} else if (info) {
		color = 'primary'
		message = info
	}

	return <div className={classNames(`toast toast-${color}`)}>{message}</div>
}

export default BaseAlert
