import React from 'react'

const KYCComplete = () => (
	<div className="section-heading text-center kyc-status">
		<i className="fad fa-badge-check fa-2x text-success" />
		<h3>ยืนยันตัวตนเรียบร้อยแล้ว</h3>
		<h6>คุณได้ยืนยันตัวตนของคุณเรียบร้อยแล้ว</h6>
	</div>
)

export default KYCComplete
