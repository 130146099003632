/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { useFormik } from 'formik'
import { FC, Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { requestAuthUser } from 'src/store/auth/auth.actions'
import { format } from 'src/utils/number'
import { chargeService } from '../../services/charge'
import BaseAlert from '../Base/BaseAlert'
import BaseButton from '../Base/BaseButton'
import BaseInput from '../Base/BaseInput'
import BaseAmountChoose from '../Base/BaseAmountChoose'

interface PromptPayModal {
    qrCode: string
    sourceID: string
}

const Promptpay = () => {
    const dispatch = useDispatch()

    const [topTab, setTopTab] = useState<string>('ordinary')

    const [completeModalAmount, setCompleteModalAmount] = useState(-1)
    const [modal, setModal] = useState<PromptPayModal | undefined>(undefined)
    useEffect(() => {
        let subscribeChargeID: NodeJS.Timeout
        if (modal) {
            subscribeChargeID = setInterval(async () => {
                const charge = await chargeService.getChargeInfo(modal.sourceID)
                if (charge.status === 'successful') {
                    dispatch(requestAuthUser())
                    setModal(undefined)
                    setCompleteModalAmount(charge.amount)
                }
            }, 2500)
        }

        return () => {
            if (subscribeChargeID) {
                clearInterval(subscribeChargeID)
            }
        }
    }, [modal, dispatch])

    const [agree, setAgree] = useState(false)
    const [error, setError] = useState<string | null>(null)
    const { values, isSubmitting, handleChange, handleSubmit } = useFormik({
        initialValues: {
            amount: 20,
        },
        onSubmit: (values, { setSubmitting }) => {
            if (values.amount < 20 || values.amount > 100000) {
                setError('จำนวนเงินต้องอยู่ระหว่าง 20 - 100,000 บาท')
                setSubmitting(false)
                return
            }

            window.Omise.createSource(
                'promptpay',
                {
                    amount: values.amount * 100,
                    currency: 'THB',
                },
                async (statusCode, response) => {
                    if (statusCode === 200) {
                        try {
                            const charge = await chargeService.createCharge({
                                amount: parseInt(values.amount.toString()),
                                currency: 'THB',
                                redirectURL: window.location.origin,

                                sourceID: response.id,
                            })

                            setModal({
                                qrCode: charge.qrCode,
                                sourceID: charge.sourceID,
                            })
                            setSubmitting(false)
                        } catch ({ message }: any) {
                            setError(message as string)
                            setSubmitting(false)
                        }
                    } else {
                        setError('ไม่สามารถทำรายการได้ในขณะนี้')
                        setSubmitting(false)
                    }
                }
            )
        },
    })
    return (
        <Fragment>
            <div className="xver-box payment-block">
                <ul className="tab tab-block">
                    <li
                        className={clsx('tab-item', {
                            active: topTab === 'ordinary',
                        })}
                    >
                        <a
                            onClick={() => setTopTab('ordinary')}
                            style={{ cursor: 'pointer' }}
                        >
                            เลือกจำนวน
                        </a>
                    </li>
                    <li
                        className={clsx('tab-item', {
                            active: topTab === 'amount',
                        })}
                    >
                        <a
                            onClick={() => setTopTab('amount')}
                            style={{ cursor: 'pointer' }}
                        >
                            กำหนดเอง
                        </a>
                    </li>
                </ul>

                <div className="box-content">
                    {error && <BaseAlert error={error} />}
                    <form autoComplete="off" onSubmit={handleSubmit}>
                        {topTab === 'ordinary' && (
                            <BaseAmountChoose
                                name="amount"
                                label="จำนวนเงิน"
                                defaultValue={values.amount}
                                required
                                onChange={handleChange}
                            />
                        )}
                        {topTab === 'amount' && (
                            <BaseInput
                                name="amount"
                                label="จำนวนเงิน"
                                defaultValue={values.amount}
                                required
                                onChange={handleChange}
                            />
                        )}
                        <div className="form-group form-topup-terms">
                            <label className="form-checkbox">
                                <input
                                    type="checkbox"
                                    onChange={() => setAgree(!agree)}
                                />
                                <i className="form-icon" />{' '}
                                ฉันยอมรับว่าการเติมเครดิตไม่สามารถขอคืนเงินได้ทุกกรณี{' '}
                                <a href="/refund-policy">อ่านเพิ่มเติม</a>
                            </label>
                        </div>
                        <div className="form-group mt-1">
                            <BaseButton
                                type="submit"
                                color="primary"
                                classes="btn-lg btn-block"
                                disabled={!agree}
                                loading={isSubmitting}
                            >
                                เติมเงิน
                            </BaseButton>
                        </div>
                    </form>
                </div>
            </div>

            <PromptPayDialog
                open={!!modal}
                modal={modal}
                close={() => setModal(undefined)}
            />
            <PromptPayCompleteDialog
                open={completeModalAmount !== -1}
                amount={completeModalAmount}
                close={() => setCompleteModalAmount(-1)}
            />
        </Fragment>
    )
}

interface PromptPayDialogProps {
    open: boolean
    modal?: PromptPayModal
    close: () => void
}
const PromptPayDialog: FC<PromptPayDialogProps> = ({ open, modal, close }) => (
    <div className={clsx('modal modal-sm promptpay-modal', { active: open })}>
        <span className="modal-overlay" onClick={close} />

        <div className="modal-container">
            <div className="modal-header text-center">
                <div className="modal-title h5">
                    เติมเงินผ่านพร้อมเพย์ (QR Code)
                </div>
                <h6>สแกน QR Code ด้วยแอพธนาคารเพื่อเติมเงิน</h6>
            </div>
            <div className="modal-body">
                <img src={modal?.qrCode} alt="QR Code" />
            </div>
            <div className="modal-footer">
                <button
                    type="button"
                    className="btn btn-default btn-block"
                    onClick={close}
                >
                    ยกเลิก
                </button>
            </div>
        </div>
    </div>
)

interface PromptPayCompleteDialogProps {
    open: boolean
    amount: number
    close: () => void
}
const PromptPayCompleteDialog: FC<PromptPayCompleteDialogProps> = ({
    open,
    amount,
    close,
}) => (
    <div
        className={clsx('modal modal-sm promptpay-complete-modal', {
            active: open,
        })}
    >
        <span className="modal-overlay" onClick={close} />

        <div className="modal-container">
            <div className="modal-body">
                <div>
                    <i className="fad fa-check-circle" />
                </div>
                <h5>เติมเงินสำเร็จ</h5>
                <h6>
                    คุณได้ทำการเติมเงินจำนวน <b>{format(amount, 0)}</b> บาท
                </h6>
            </div>
            <div className="modal-footer">
                <button
                    type="button"
                    className="btn btn-default btn-block"
                    onClick={close}
                >
                    ตกลง
                </button>
            </div>
        </div>
    </div>
)

export default Promptpay
