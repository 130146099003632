import React from 'react'
import { useSelector } from 'react-redux'
import { KYCLevel, KYCStatus, User } from '../../../@types/user'
import { ApplicationState } from '../../../store'
import KYCComplete from './KYCComplete'
import KYCLevel1 from './KYCLevel1'
import KYCLevel2 from './KYCLevel2'
import KYCPending from './KYCPending'

const AccountVerficationTab = () => {
	const renderVerifyContent = (user: User) => {
		if (user.kyc.status === KYCStatus.Pending) {
			return <KYCPending />
		}

		switch (user.kyc.level) {
			case KYCLevel.L0: {
				return <KYCLevel1 user={user} />
			}
			case KYCLevel.L1: {
				return <KYCLevel2 />
			}
			default: {
				return <KYCComplete />
			}
		}
	}

	const authState = useSelector((state: ApplicationState) => state.auth)
	if (!authState.user) {
		return null
	}
	return (
		<div className="columns">
			<div className="column col-10 col-sm-12 col-mx-auto">{renderVerifyContent(authState.user)}</div>
		</div>
	)
}

export default AccountVerficationTab
