import React, { FC, useState } from 'react'
import { toast } from 'react-toastify'
import { mutate } from 'swr'
import { SSHKey } from '../../../@types/sshKey'
import { sshKeyService } from '../../../services/sshKey'
import BaseButton from '../../Base/BaseButton'

interface SSHKeyListTableProps {
	data: SSHKey[]
}
const SSHKeyList: FC<SSHKeyListTableProps> = ({ data }) => {
	const [loading, setLoading] = useState(0)
	const deleteKey = async (id: number) => {
		if (loading) {
			return
		}
		setLoading(id)

		try {
			const res = await sshKeyService.deleteSSHKey(id)

			mutate('/v1/ssh-keys')
			toast.success(res.message)
		} catch ({ message }) {
			toast.error(message as string)
		} finally {
			setLoading(0)
		}
	}

	return (
		<div className="xver-ssh-keys-wrapper">
			{data.length > 0 ? (
				data.map((k) => (
					<div className="xver-ssh-key-item" key={k.id}>
						<div>
							<h5>
								{k.name} <small>({k.type})</small>
							</h5>
							<h6>{k.fingerprint}</h6>
						</div>

						<BaseButton
							type="button"
							color="error"
							classes="no-mr"
							loading={loading === k.id}
							onClick={() => deleteKey(k.id)}
						>
							<i className="fad fa-trash-alt" />
						</BaseButton>
					</div>
				))
			) : (
				<div className="xver-ssh-key-item text-center">No SSH Keys</div>
			)}
		</div>
	)
}

export default SSHKeyList
