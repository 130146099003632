import clsx from 'clsx'
import React, { FC, SelectHTMLAttributes, useState } from 'react'

interface BaseInputProps {
	label: string
}
const BaseSelect: FC<BaseInputProps & SelectHTMLAttributes<HTMLSelectElement>> = ({
	label,
	name,
	className,
	onChange,
	children,
	...rest
}) => {
	const [value, setValue] = useState(rest.defaultValue)

	return (
		<label className="form-floating-label" htmlFor={name}>
			<span className={clsx({ active: value !== '' })}>{label}</span>
			<select
				name={name}
				className={clsx(className, { active: value !== '' })}
				placeholder={label}
				onChange={(e) => {
					setValue(e.currentTarget.value)
					onChange && onChange(e)
				}}
				{...rest}
			>
				{children}
			</select>
		</label>
	)
}

export default BaseSelect
