import { useFormik } from 'formik'
import React from 'react'
import { toast } from 'react-toastify'
import { mutate } from 'swr'
import { sshKeyService } from '../../../services/sshKey'
import BaseButton from '../../Base/BaseButton'

const AddSSHKeySection = () => {
	const { isSubmitting, handleChange, handleSubmit } = useFormik({
		initialValues: {
			name: '',
			content: '',
		},
		onSubmit: async (values) => {
			try {
				const res = await sshKeyService.addSSHKey(values)

				mutate('/v1/ssh-keys')
				toast.success(res.message)
			} catch ({ message }) {
				toast.error(message as string)
			}
		},
	})

	return (
		<div className="xver-box">
			<div className="box-content">
				<form autoComplete="off" onSubmit={handleSubmit}>
					<div className="form-group">
						<label className="form-label">Name</label>
						<input
							type="text"
							name="name"
							className="form-input"
							placeholder="Name"
							required
							onChange={handleChange}
						/>
					</div>
					<div className="form-group">
						<label className="form-label">Public Key</label>
						<textarea
							name="content"
							className="form-input"
							placeholder="ssh-rsa AAAA.... you@example.com"
							rows={5}
							required
							onChange={handleChange}
						/>
					</div>
					<div className="form-group mt-1">
						<BaseButton type="submit" color="primary" classes="btn-lg btn-block" loading={isSubmitting}>
							Add
						</BaseButton>
					</div>
				</form>
			</div>
		</div>
	)
}

export default AddSSHKeySection
