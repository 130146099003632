import Compressor from 'compressorjs'
import { ChangeEvent, createRef, DragEvent, Fragment, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { kycService } from '../../../services/kyc'
import { setAuthUser } from '../../../store/auth/auth.actions'
import BaseButton from '../../Base/BaseButton'
import { useHistory } from 'react-router'

const KYCLevel2 = () => {
    const history = useHistory()
	const dragCounter = useRef(0)
	const [showOverlay, setShowOverlay] = useState(false)

	const uploadInputRef = createRef<HTMLInputElement>()
	const [agree, setAgree] = useState(false)
	const [uploadFile, setUploadFile] = useState<File | undefined>(undefined)

	const onDragEnter = (e: DragEvent) => {
		e.preventDefault()
		e.stopPropagation()

		dragCounter.current++
		if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
			setShowOverlay(true)
		}
	}
	const onDragLeave = (e: DragEvent) => {
		e.preventDefault()
		e.stopPropagation()

		if (--dragCounter.current <= 0) {
			setShowOverlay(false)
		}
	}

	const filterAndSetUploadFile = (file: File) => {
		if (!['image/png', 'image/jpeg', 'image/jpg'].includes(file.type)) {
			toast.error('รองรับไฟล์ PNG, JPG, JPEG เท่านั้น')
			return false
		}

		if (file.size > ((20 * 1024) * 1024)) {
			new Compressor(file, {
				quality: 0.6,
				success(compressedResult) {
					setShowOverlay(true)
					setUploadFile(compressedResult as File)
				},
			})

			// toast.error('ขนาดไฟล์ต้องไม่เกิน 20MB')
			// return false
		}

		setShowOverlay(true)
		setUploadFile(file)
	}
	const onDrop = (e: DragEvent) => {
		e.preventDefault()
		e.stopPropagation()

		setShowOverlay(false)
		if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
			filterAndSetUploadFile(e.dataTransfer.files[0])
			e.dataTransfer.clearData()
		}
	}
	const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.currentTarget.files && e.currentTarget.files.length > 0) {
			filterAndSetUploadFile(e.currentTarget.files[0])
		}
	}

	const dispatch = useDispatch()
	const [loading, setLoading] = useState(false)
	const onClickSubmit = async () => {
		if (!uploadFile) {
			return false
		}

		setLoading(true)

		try {
			const res = await kycService.confirmKYCLevel2(uploadFile)

			dispatch(setAuthUser(res.data))
			toast.success(res.message)

            return history.push("/cloud-servers");

		} catch ({ message }: any) {
			setLoading(false)
			return toast.error(message as string)
		}
	}

	return (
		<Fragment>
			<div className="section-heading text-center">
				<i className="fad fa-id-card fa-2x" />
				<h3>
					ยืนยันตัวตนขั้นที่ <b>2</b>
				</h3>
			</div>
			<div className="kyc-id-card-wrapper">
				<div className="kyc-id-card-info text-center">
					เอกสารส่วนตัวของคุณเช่นบัตรประจำตัวประชาชน หรือ พาสปอร์ต พร้อมรับรองสำเนาถูกต้อง
				</div>
				<div
					className="kyc-id-card-drop"
					onDragEnter={onDragEnter}
					onDragLeave={onDragLeave}
					onDragOver={(e) => e.preventDefault()}
					onDrop={onDrop}
					onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
				>
					<div
						className="kyc-id-card-drop-overlay"
						style={{ display: showOverlay ? 'flex' : 'none' }}
					>
						<i className="fad fa-address-card fa-2x" />
						<span>{uploadFile && uploadFile.name}</span>
					</div>
					<div className="kyc-id-card-drop-content">
						<h3>วางไฟล์ หรือ คลิกที่นี่เพื่ออัพโหลด</h3>
						<h6>รองรับ PNG, JPG, JPEG เท่านั้น - ขนาดสูงสุด 20MB</h6>
					</div>
				</div>
				<input
					type="file"
					style={{ display: 'none' }}
					ref={uploadInputRef}
					onChange={onFileChange}
				/>

				<div className="form-group form-kyc-info">
					* เมื่อยืนยันตัวตนสำเร็จ คุณจะสามารถสร้างได้สูงสุด <b>10</b> เซิร์ฟเวอร์ *
				</div>
				<div className="form-group form-kyc-terms">
					<label className="form-checkbox">
						<input type="checkbox" onChange={() => setAgree(!agree)} />
						<i className="form-icon" /> ฉันยอมรับว่าเอกสารถูกต้อง
						และตกลงให้ความยินยอมรวมถึงอนุญาตตามเงื่อนไขที่กำหนดทั้งหมด
					</label>
				</div>
				<div className="form-group mt-1">
					<BaseButton
						type="button"
						color="primary"
						classes="btn-lg btn-block"
						loading={loading}
						disabled={!uploadFile || !agree}
						onClick={onClickSubmit}
					>
						ยืนยัน
					</BaseButton>
				</div>
			</div>
		</Fragment>
	)
}

export default KYCLevel2
