import React from 'react'
import ChangePasswordSection from './ChangePasswordSection'

const SecurityTab = () => {
	return (
		<div className="columns">
			<div className="column col-8 col-sm-12 col-mx-auto">
				<ChangePasswordSection />
			</div>
		</div>
	)
}

export default SecurityTab
