import { User, UserInformation } from '../@types/user'
import api from '../utils/api'

type ConfirmKYCLevel1Payload = UserInformation
interface ConfirmKYCLevel1Response {
	message: string
	data: User
}

interface ConfirmKYCLevel2Response {
	message: string
	data: User
}

export const kycService = {
	confirmKYCLevel1(payload: ConfirmKYCLevel1Payload): Promise<ConfirmKYCLevel1Response> {
		return api.post('/v1/kyc/level-1', payload)
	},
	confirmKYCLevel2(file: File): Promise<ConfirmKYCLevel2Response> {
		const compressedFilePayload = new FormData()
		compressedFilePayload.append("id-card", file)

		return api.post('/v1/kyc/level-2', compressedFilePayload, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
	},
}
