import React from "react";

import BaseInput from "src/components/Base/BaseInput";
import { ApplicationState } from "src/store";
import { useSelector } from "react-redux";
import { EmailLevel } from "src/@types/user";

export default function AccountInformation(): Readonly<React.ReactElement> {
    const authState = useSelector((state: ApplicationState) => state.auth);

    return (
        <div>
            <div
                style={{
                    marginBottom: '24px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'left',
                    alignContent: 'center',
                    flexWrap: 'nowrap'
                }}
            >
                <h3 style={{ margin: '0' }}>รายละเอียดบัญชีของคุณ</h3>
                <span style={{ color: '#7c7c7c' }}>ข้อมูลบัญชีของคุณทั้งหมดที่คุณได้ให้ข้อมูลมา</span>
            </div>
            <form autoComplete="off" className="width-95">
                <div className="columns">
                    <div className="column col-12">
                        <div
                            style={{
                                marginBottom: 16,
                                padding: 8,
                                display: "flex",
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: 6,
                                backgroundColor: authState.user?.status === "active" ? "#22c55e" : authState.user?.status === "insufficient_funds" ? "#dca105" : "#ef4444",
                                fontWeight: 500,
                                color: "#ffffff"
                            }}
                        >
                            {authState.user?.status === "active" ? "บัญชีของคุณสามารถใช้งานได้ตามปกติ" : authState.user?.status === "insufficient_funds" ? "บัญชีของคุณเครดิตไม่เพียงพอต่อการใช้งาน" : "บัญชีของคุณโดนระงับการใช้งาน"}
                        </div>
                    </div>
                    <div className="column col-6">
                        <span style={{ color: '#7c7c7c' }}>อีเมลของคุณ</span>
                        <BaseInput
                            type="text"
                            name="email_address"
                            label="อีเมล"
                            defaultValue={authState.user?.email}
                            disabled
                        />
                    </div>
                    <div className="column col-6">
                        <span style={{ color: '#7c7c7c' }}>เบอร์โทรศัพท์ของคุณ</span>
                        <BaseInput
                            type="text"
                            name="phone_number"
                            label="เบอร์โทรศัพท์"
                            defaultValue={authState.user?.phoneNumber}
                            disabled
                        />
                    </div>
                    <div className="column col-12">
                        <span style={{ color: '#7c7c7c' }}>ที่อยู่ของคุณ</span>
                        <BaseInput
                            type="text"
                            name="address"
                            label="ที่อยู่"
                            defaultValue={authState.user?.information.address}
                            disabled
                        />
                    </div>
                    <div className="column col-6">
                        <span style={{ color: '#7c7c7c' }}>ระดับการยืนยันตัวตนของคุณ</span>
                        <BaseInput
                            type="text"
                            name="kyc"
                            label="ระดับการยืนยันตัวตน"
                            defaultValue={authState.user?.kyc.level}
                            disabled
                        />
                    </div>
                    <div className="column col-6">
                        <span style={{ color: '#7c7c7c' }}>สถานะการยืนยันอีเมลของคุณ</span>
                        <BaseInput
                            type="text"
                            name="kyc"
                            label="การยืนยันอีเมล"
                            defaultValue={authState.user?.verifyEmailAddress !== EmailLevel.E2 ? "ยังไม่ได้ยืนยัน" : "ยืนยันแล้ว"}
                            disabled
                        />
                    </div>
                </div>
            </form>
        </div>
    );
}
